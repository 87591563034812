import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Asset, Category, Product } from '@webcoffee/interfaces';
import { EMPTY, Observable, catchError, filter, switchMap, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';

export interface ProductState {
    products: Product[];
    categories: Category[];
    productsCount: number | null;
    selectedProduct: Product | null;
    loading: boolean;
    editMode: boolean;
    query: { skip: number; take: number; search?: string; status?: string[]; categories?: string[] };
}

@Injectable()
export class ProductStore extends ComponentStore<ProductState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super({
            products: [],
            categories: [],
            productsCount: null,
            selectedProduct: null,
            loading: false,
            editMode: false,
            query: { skip: 0, take: 20 },
        });
        this.getCategoriesEffect();
    }

    readonly getProducts = this.select((state) => state.products);
    readonly getProductsLength = this.select((state) => state.productsCount);
    readonly getQuery = this.select((state) => state.query);
    readonly getCategories = this.select((state) => state.categories);
    readonly getEditMode = this.select((state) => state.editMode);
    readonly getSelectedProduct = this.select((state) => state.selectedProduct);
    readonly getNewProduct = this.select(this.getEditMode, this.getSelectedProduct, (editMode, selectedProduct) => editMode && !selectedProduct);
    readonly getProviderItems = this.select(this.getSelectedProduct, (product) => product?.provider_items);
    readonly getActiveProviderItem = this.select(this.getProviderItems, (providerItems) => providerItems?.find((providerItem) => providerItem.verified && providerItem.sync));
    readonly getProviderNotVerified = this.select(this.getProviderItems, (providerItems) => providerItems?.some((providerItem) => !providerItem.verified));
    readonly getProviderDeactivate = this.select(this.getProviderItems, (providerItems) => providerItems?.some((providerItem) => providerItem.verified && providerItem.deactivate));

    readonly updateQuery = this.updater((state, payload: any) => {
        const query = { ...state.query, ...{ ...payload, categoryInput: undefined } };
        if (!query.search || query.search.trim() === '') delete query.search;
        this.router.navigate([], { relativeTo: this.route, queryParams: query });

        return { ...state, query };
    });

    readonly addAsset = this.updater((state, payload: Asset) => {
        return { ...state, selectedProduct: { ...state.selectedProduct, assets: [...state.selectedProduct.assets, payload] } };
    });
    readonly removeAsset = this.updater((state, payload: string) => {
        return { ...state, selectedProduct: { ...state.selectedProduct, assets: [...state.selectedProduct.assets].filter((a) => a.id !== payload) } };
    });

    readonly watchProductIdEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            tap((id: string) => {
                if (id) {
                    if (id === 'nou') {
                        this.patchState({ loading: false, editMode: true, selectedProduct: null });
                    } else {
                        if (this.get().selectedProduct?.id !== id) {
                            this.patchState({ loading: true, editMode: true });
                            this.getProductEffect(id);
                        }
                    }
                }
            }),
        ),
    );

    readonly getCategoriesEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                return this.http.get<Category[]>('/api/categories').pipe(
                    tap((categories) => {
                        this.patchState({ categories });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly getProductsEffect = this.effect((params$: Observable<any>) =>
        params$.pipe(
            filter((params) => !!params && Object.keys(params)?.length !== 0),
            switchMap((params) => {
                let decodedParams = new HttpParams();

                for (const [queryKey, queryVal] of Object.entries(params)) {
                    if (typeof queryVal === 'string') {
                        decodedParams = decodedParams.append(queryKey, queryVal);
                    } else {
                        decodedParams = decodedParams.append(queryKey, JSON.stringify(queryVal));
                    }
                }
                return this.http.get<{ count: number; items: Product[] }>('/api/products/search', { params: decodedParams }).pipe(
                    tap((countAndProducts) => {
                        this.patchState({ productsCount: countAndProducts.count, products: countAndProducts.items });
                    }),
                );
            }),
        ),
    );

    readonly getProductEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.get<Product>(`api/products/${id || this.get().selectedProduct?.id}`).pipe(
                    tap((product) => {
                        this.patchState({ selectedProduct: product, loading: false });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );
}
